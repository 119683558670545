import React, { useState } from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import CallToAction from '../components/call-to-action'
import HomePanel from '../components/home-panel'
import Hero from '../components/hero'
import Promotion from '../components/promotion'
import SEO from '../components/seo'
import PopPup from '../components/pop-pup'

const title1 = `A website in minutes. Really.`
const title2 = `Your website. Your way. `
const title3 = `Insights and analytics`
const title4 = `Over 1,000,000 free images from Unsplash`
const title5 = `Support`
const title6 = `Free domain registration `
const title7 = `Hire an expert to build your site`

const description1 = `Answer a few questions about your business, blog, or portfolio and our A.I. builder will start building a site based on your answers. No coding required. `
const description2 = `Pick your perfect colors, fonts, and layout. The simple interface automatically builds your site using smart design techniques and best practices so you get a polished, professional result. `
const description3 = `See engagement stats and visitor metrics in real-time. Understanding how people use your site will help you improve its performance over time.`
const description4 = `Finding quality web-ready images that aren’t copyrighted takes time and money. Get a headstart with our free library. You can always use your own images, too.`
const description5 = `Chat us. Email us. We can walk you through connecting a domain, editing your site, setting up an ecommerce store and lots more.`
const description6 = `Qualifying website plans come with one year of domain registration. All you have to do is choose the best plan for your project and start building. `
const description7 = `Web.com offers flexible and affordable custom website design services backed by a team of experts available to support your business needs. 
                      Let our team of experienced professionals build your website and establish your online presence.`

const ctaText = 'Get Started'
const badgeText = 'OFF*'
const noteText = 'See promotional details below'
const learMoreText = 'Learn More'

const Home = ({
  data: {
    PanelImage1,
    PanelImage2,
    PanelImage3,
    PanelImage4,
    PanelImage5,
    PanelImage6,
    PanelImage7
  }
}) => {
  const [callToAction, setCallToAction] = useState(false)
  const [callToActionB, setCallToActionB] = useState(false)
  const redirectWebdotcom = (websiteUrl, setCall) => {
    setTimeout(() => {
      document.location.href = websiteUrl
      setCall(false)
    }, 5000)
  }
  const startOnboarding = (setCall, website) => {
    setCall(true)
    redirectWebdotcom(website, setCall)
  }
  return (
    <Layout>
      <SEO
        title='Create Your Own Website with Sitebuilder.com'
        keywords={[
          `web hosting`,
          `domain names`,
          `web site`,
          `search engine optimization`,
          `hosting`,
          `servers`
        ]}
        pageType={'home'}
        flowType={'none'}
      />
      <Hero />
      <HomePanel
        dark
        gatsbyImage={PanelImage1.childImageSharp.fluid}
        title={title1}
        description={description1}
        alignment='right'
      />
      <HomePanel
        gatsbyImage={PanelImage2.childImageSharp.fluid}
        title={title2}
        description={description2}
        alignment='left'
      />
      <HomePanel
        dark
        gatsbyImage={PanelImage3.childImageSharp.fluid}
        title={title3}
        description={description3}
        alignment='right'
      />
      <HomePanel
        gatsbyImage={PanelImage4.childImageSharp.fluid}
        title={title4}
        description={description4}
        alignment='left'
      />
      <HomePanel
        dark
        gatsbyImage={PanelImage5.childImageSharp.fluid}
        title={title5}
        description={description5}
        alignment='right'
      />
        <HomePanel
          gatsbyImage={PanelImage6.childImageSharp.fluid}
          title={title6}
          description={description6}
          alignment='left'
          popup={<PopPup callToAction={callToAction} setCallToAction={setCallToAction} />}
          button={
            <CallToAction 
              dataElementId='call-to-action-button-bottom'
              onClick={() => startOnboarding(setCallToAction, 'https://www.web.com/domains')} 
              badgeText={badgeText} 
              noteText={noteText} 
              ctaText={ctaText} 
              showBadge 
              black/>}
        />
      <HomePanel
        gatsbyImage={PanelImage7.childImageSharp.fluid}
        title={title7}
        description={description7}
        alignment='right'
        popup={<PopPup callToAction={callToActionB} setCallToAction={setCallToActionB} />}
        button={
            <CallToAction 
              black 
              showBadge={false} 
              ctaText={learMoreText} 
              onClick={() => startOnboarding(setCallToActionB, 'https://www.web.com/websites/pro-website-services')} />
          }
      />
      <Promotion />
    </Layout>
  )
}

export default Home


export const query = graphql`
  query {
    PanelImage1: file(relativePath: { eq: "easy.png" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    PanelImage2: file(relativePath: { eq: "customize.png" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    PanelImage3: file(relativePath: { eq: "insights.png" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    PanelImage4: file(relativePath: { eq: "library.png" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    PanelImage5: file(relativePath: { eq: "support.png" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    PanelImage6: file(relativePath: { eq: "start.png" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    PanelImage7: file(relativePath: { eq: "crossSell.png" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
