import styled from 'styled-components'
import BackgroundSection from './background'

const Hero = styled(BackgroundSection)`
  background-size: auto 100vh;
  background-repeat: no-repeat;
  background-color: #000;
  background-position: center right;
  height: 100vh;
  width: 100%;
  position: relative;
  overflow: hidden;
  display: block;
  &:after,
  &:before,
  & {
    @media (max-width: 1300px) {
      height: 60vh !important;
      min-height: 450px;
      background-size: auto 100% !important;
    }
    @media (max-width: 800px) {
      background-size: 100vw auto !important;
      height: 80vh !important;
      background-position: top center !important;
    }
    @media (max-width: 500px) {
      max-height: 550px !important;
    }
  }
`
Hero.backdrop = styled.div`
  position: absolute;
  width: 1600px;
  height: 100%;
  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 1) 20%,
    rgba(0, 0, 0, 0) 40%
  );
`
Hero.mobileBackDrop = styled.div`
  position: absolute;
  width: 1600px;
  height: 100%;
  background: linear-gradient(0deg, rgba(0, 0, 0, 1) 30%, rgba(0, 0, 0, 0) 50%);
`
Hero.actionHolder = styled.div`
  position: absolute;
  width: 360px;
  top: 50%;
  transform: translate(0, -50%);
  left: 130px;
`
Hero.text = styled.p`
  font-size: 42pt;
  color: white;
  margin: 0;
  font-weight: 700;
  padding-bottom: 20px;
`

Hero.mobileActionHolder = styled.div`
  position: absolute;
  font-size: 8vw;
  text-align: center;
  color: white;
  width: 71vw;
  bottom: 65px;
  left: 50%;
  transform: translate(-50%, 0);
`

Hero.popupContainer = styled.div`
  position:absolute;
  left:0;
  width: 100%;
  max-width: 400px;
  bottom:280px;

  @media (max-width:768px){
    left:50%;
    bottom:345px;
    transform:translateX(-50%);
  }
`

export default Hero
