import React from 'react'
import PropTypes from 'prop-types'
import Panel from './panel.styles'

const HomePanel = ({
  dark,
  gatsbyImage,
  title,
  description,
  alignment = 'left',
  button,
  popup
}) => {
  return (
    <Panel alignment={alignment} dark={dark}>
      <Panel.section>
        <Panel.title>{title}</Panel.title>
        <Panel.description>{description}</Panel.description>
        <Panel.buttonHolder>{button && button}</Panel.buttonHolder>
        <Panel.popupContainer>{popup && popup}</Panel.popupContainer>
      </Panel.section>
      <Panel.section>
        <Panel.image fluid={gatsbyImage} />
      </Panel.section>
    </Panel>
  )
}

HomePanel.propTypes = {
  dark: PropTypes.bool,
  gatsbyImage: PropTypes.object.isRequired,
  title: PropTypes.string,
  description: PropTypes.string,
  alignment: PropTypes.string,
  button: PropTypes.node
}

export default HomePanel
