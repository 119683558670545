import React, { useState } from 'react'
import Hero from './hero.styles'
import CallToAction from '../call-to-action'
import useMobileDetection from '../../helpers/hooks/useMobileDetection'
import PopPup from '../pop-pup'

const actionCopy = 'Build a professional website. Fast.'

const HeroComponent = () => {
  const [isMobile, showChildren] = useMobileDetection(800)
  const [callToAction, setCallToAction] = useState(false)
  const ctaText = 'Get Started'
  const badgeText = 'OFF*'
  const noteText = 'See promotional details below'

  const redirectWebdotcom = (websiteUrl) => {
    setTimeout(() => {
      document.location.href = websiteUrl
      setCallToAction(false)
    }, 5000)
  }

  const startOnboarding = () => {
    setCallToAction(true)
    redirectWebdotcom('https://www.web.com/websites/online-store-builder')
  }

  return (
    <Hero>
      {showChildren &&
        (isMobile ? (
          <>
            <Hero.mobileBackDrop />
            <Hero.mobileActionHolder>
              {actionCopy}
              <CallToAction badgeText={badgeText} ctaText={ctaText} noteText={noteText} onClick={startOnboarding} dataElementId='call-to-action-button-top'/>
            </Hero.mobileActionHolder>
            <Hero.popupContainer>
              <PopPup callToAction={callToAction} setCallToAction={setCallToAction}  />
            </Hero.popupContainer>
          </>
        ) : (
          <>
            <Hero.backdrop />
            <Hero.actionHolder>
              <Hero.text>{actionCopy}</Hero.text>
              <CallToAction  badgeText={badgeText} ctaText={ctaText} noteText={noteText} onClick={startOnboarding} dataElementId='call-to-action-button-top'/>
              <Hero.popupContainer>
                <PopPup callToAction={callToAction} setCallToAction={setCallToAction}  />
              </Hero.popupContainer>
            </Hero.actionHolder>
          </>
        ))}
    </Hero>
  )
}

export default HeroComponent
