import styled from 'styled-components'
import Button from '@eig-builder/core-ui/Button'

const Action = styled.div`
  padding-top: 15px;
  position: relative;
  &:after {
    content: '';
    width: ${props => (props.black && props.showBadge ? '70px' : '0px')};
    display: inline-block;
  }
`
Action.Button = styled(Button).attrs(() => ({
  variant: 'contained',
  size: 'xl'
}))`
  border-radius: 40px !important;
  padding: 0px 50px !important;
  ${props => props.isWhite ? 'background-color: #d09624 !important; border: none !important;' : ''}
`
Action.MobileButton = styled(Button).attrs(() => ({
  variant: 'contained',
  size: 'xl'
}))`
  border-radius: 40px !important;
  ${props => props.isWhite ? 'background-color: #d09624 !important; border: none !important;' : ''}
`
Action.discount = styled.div`
  pointer-events: none;
  position: absolute;
  top: 0px;
  right: 20px;
  transform: translate(100%, -25px);
  display: flex;
  flex-wrap: wrap;
  width: 300px;
  align-items: center;
  z-index: 10;
`
Action.discountImage = styled.img``
Action.discountAmount = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 22pt;
  line-height: 1em;
  ${props => props.isWhite ? 'color: #d09624 !important;' : ''}
  @media (max-width: 800px) {
    font-size: 18pt;
  }
`
Action.discountOff = styled.div`
  font-size: 12pt;
  text-align: center;
  line-height: 1em;
  @media (max-width: 800px) {
    font-size: 10pt;
  }
`
Action.discountImageContainer = styled.span`
  position: relative;
  display: inline-block;
`
Action.discountText = styled.p`
  font-family: Roboto;
  color: ${props => (props.black ? 'black' : 'white')};
  font-size: 10pt;
  font-weight: 300;
  text-align: left;
  margin: 10px;
  width: 120px;
  line-height: 1.3em;
`

Action.discountMobile = styled.div`
  pointer-events: none;
  position: absolute;
  top: 0;
  right: 30px;
  transform: translate(100%, -10px);
  align-items: center;
  z-index: 10;
`
Action.discountImageMobile = styled.img`
  border-radius: 100%;
`
Action.discountTextMobile = styled.p`
  font-family: Roboto;
  text-align: center;
  margin: 0;
  color: ${props => (props.black ? 'black' : 'white')};
  font-size: 8pt;
  font-weight: 300;
  width: 100px;
`

export default Action
