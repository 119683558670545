import styled from 'styled-components'
import Img from 'gatsby-image'

const Panel = styled.div`
  display: flex;
  background-color: ${props => (props.dark ? '#f6f6f6' : '#fff')};
  justify-content: center;
  align-items: center;
  flex-direction: ${props =>
    props.alignment === 'left' ? 'row' : 'row-reverse'};
  flex-wrap: wrap-reverse;
  padding: 80px ${({ theme }) => theme.spacing(4)}px;
  overflow: hidden;
  @media (max-width: 800px) {
    padding: 50px 0;
  }
`
Panel.section = styled.div`
  position: relative;
  // font-family: sans-serif;
  min-width: 400px;
  max-width: 600px;
  padding: 10px 20px;
  text-align: left;
  flex: 1 1 0;
  @media (max-width: 400px) {
    min-width: 320px;
  }
`
Panel.title = styled.p`
  margin-top: 0;
  font-size: 24pt;
  font-weight: bold;
  color: #333;
`
Panel.image = styled(Img)`
  max-width: 100%;
`

Panel.description = styled.p`
  color: #666;
  font-weight: normal;
  line-height: 1.5em;
`
Panel.buttonHolder = styled.div`
  color: black !important;
  margin: 0 auto;
  text-align: left;
  @media (max-width: 800px) {
    text-align: center;
  }
`
Panel.popupContainer = styled.div`
  position:relative;
  bottom: 280px;
`

export default Panel
