import React from 'react'
import PropTypes from 'prop-types'
import Action from './action.styles'
import useMobileDetection from '../../helpers/hooks/useMobileDetection'

import { DataElementLocations } from '@eig-builder/core-utils/helpers/tagging-helper'

const CallToAction = ({ black, badgeText, noteText, ctaText, showBadge=true, isWhite, onClick, dataElementId='sitebuilder-call-to-action' }) => {
  const [isMobile, showChildren] = useMobileDetection(800)
  return (
    <>
      {showChildren &&
        (!isMobile ? (
          <Action>
            <Action.Button
              onClick={onClick}
              dataElementLocation={DataElementLocations.BODY}
              dataElementLabel='body.call-to-action'
              dataElementId={dataElementId}
              variant={'contained'}
              isWhite={isWhite}
            >
              {ctaText}
            </Action.Button>
          </Action>
        ) : (
          <Action black={black} showBadge={showBadge}>
            <Action.MobileButton
              onClick={onClick}
              dataElementLocation={DataElementLocations.BODY}
              dataElementLabel='body.call-to-action'
              dataElementId={dataElementId}
              isWhite={isWhite}
            >
              {ctaText}
            </Action.MobileButton>
          </Action>
        ))}
    </>
  )
}

CallToAction.propTypes = {
  black: PropTypes.bool,
  badgeText: PropTypes.string,
  noteText: PropTypes.string,
  ctaText: PropTypes.string,
  showBadge: PropTypes.bool,
  onClick: PropTypes.func,
  dataElementId: PropTypes.string
}

export default CallToAction
