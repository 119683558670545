import React from 'react'
import styled from 'styled-components'
import { getCurrencySymbol } from 'helpers/currency-helper'

const Promotion = styled.div`
  background-color: #464848;
  padding: 30px 40px;
  color: white;
  font-weight: 100;
  font-size: 0.8em;
`
Promotion.Link = styled.a`
  font-weight: bold;
  text-decoration: underline;
  color: white;
`

const PromotionalComponent = () => {
  return (
    <Promotion>
      *The promotional price is for the first term of service only and
      automatically renews at{' '}
      <Promotion.Link
        href={process.env.GATSBY_REGULAR_RATES}
        target='_blank'
        rel='noopener norefferer'
      >
        regular rates
      </Promotion.Link>
      . For customers in regions in which VAT (Value Added Tax) or GST (Goods
      and Services Tax) is applicable, taxes are not included in our advertised
      price and will be charged separately and itemized on invoices and billing
      information. Qualified plans include a free domain registration for one
      year. If you wish to cancel, there is a non-refundable{' '}
      {getCurrencySymbol().domainPrice} domain fee.{' '}
      <Promotion.Link
        href={process.env.GATSBY_EU_VAT}
        target='_blank'
        rel='noopener norefferer'
      >
        Learn more
      </Promotion.Link>
    </Promotion>
  )
}

export default PromotionalComponent
